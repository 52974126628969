import React from 'react'

import { Link } from 'gatsby'
import solutionIntroStyles from './solutions-introduction.module.scss'
import { StaticImage } from "gatsby-plugin-image"

const SolutionsIntro = (props) => {

    console.log(props)

    let response

    if (props.submit) {
        response = 
            <div className={solutionIntroStyles.solutioninfo__response}>
                <p>Thank you! We'll be in touch shortly!</p>
            </div>
    }

    return (
        <section className={solutionIntroStyles.solutioninfo}>

            <div className={solutionIntroStyles.solutioninfo__main}>

                <div className={"container " + solutionIntroStyles.solutioninfo__container}>

                    <div className={solutionIntroStyles.solutioninfo__title}>
                        <h1 className="hintro" dangerouslySetInnerHTML={{ __html: props.title }} />
                    </div>

                    <div className={solutionIntroStyles.solutioninfo__text}>
                        {props.children}
                    </div>

                    {response}

                </div>

            </div>

            <div className={solutionIntroStyles.solutioninfo__image}></div>

            <div className={solutionIntroStyles.solutioninfo__list}>
                <div className={solutionIntroStyles.solutioninfo__item}>

                    <div className={solutionIntroStyles.solutioninfo__item__background}>
                        <StaticImage
                            src="../images/features/london-bridge-comp.jpg"
                            alt="Cloud Solution Background"
                            layout="fullWidth"
                            quality="10"
                            placeholder="tracedSVG"
                        />
                    </div>

                    <div className={solutionIntroStyles.solutioninfo__item__icon}>
                        <i className="icon icon-cloud"></i>
                    </div>

                    <div className={solutionIntroStyles.solutioninfo__item__text}>
                        <p>Solution</p>
                        <h2>Cloud Hosting</h2>
                        <p>Ready-built affordable cloud hosting with cPanel and high availability.</p>
                    </div>

                    <Link to="/cloud-hosting/" className="button button--large button__orange">Learn More <i className="icon icon-right-small"></i></Link>
                        
                </div>

                <div className={solutionIntroStyles.solutioninfo__item}>

                    <div className={solutionIntroStyles.solutioninfo__item__background}>
                        <StaticImage
                            src="../images/features/wordpress-website-comp.jpg"
                            alt="WordPress Solution Background"
                            layout="fullWidth"
                            quality="10"
                            placeholder="tracedSVG"
                        />
                    </div>

                    <div className={solutionIntroStyles.solutioninfo__item__icon}>
                        <i className="icon icon-wordpress"></i>
                    </div>

                    <div className={solutionIntroStyles.solutioninfo__item__text}>
                        <p>Solution</p>
                        <h2>WordPress Hosting</h2>
                        <p>High-performance WordPress Hosting built for the cloud.</p>
                    </div>

                    <Link to="/wordpress-hosting/" className="button button--large button__orange">Learn More <i className="icon icon-right-small"></i></Link>

                </div>

                {/*<div className={solutionIntroStyles.solutioninfo__contact}>
                    Contact
                </div>*/}

            </div>

            <div className={solutionIntroStyles.solutioninfo__wave}></div>

        </section>
    )
}

export default SolutionsIntro