import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import SolutionsIntro from '../components/solutions-introduction'
import Logos from '../components/logos'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const Solutions = ({location}) => {
    const { state = {} } = location
    const { name } = state

    let response = false
    
    if (name) {
        response = true
    }
    
    return (
        <Store>
            <Head title="High Availability cPanel Hosting" />
            <Navigation />
            <Header disable dark/>
            <SolutionsIntro title="Better Hosting <span>Starts Here</span>" submit={response}>
                <p><strong>Fully managed, </strong>reliable, <strong>affordable,</strong> scalable, and <strong>up to 12x faster</strong> than the competition! Level up your website with our cloud hosting powered by cPanel.</p>
            </SolutionsIntro>
            <Logos/>
            <Talk/>
            <Footer/>
            <Cookies/>
        </Store>
    )
}

export default Solutions
